/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import Image from "../Image";
import PropTypes from "prop-types";
import { TopRecognitionContainerV2, RecognitionReceivedContainerV2, RecognitionReceivedHeaderV2, RecognisedUserV2, UserProfileContainerV2, UserNameContainerV2, NameV2, RecognitionFrequencyV2, NewLabelV2, YearDropDownV2, GridCardV2, CardContainerV3, CardContainerr } from "./styles";
// import { MenuItem } from "react-bootstrap";
import { ImageUrl } from '../../utils/constants';
import CSVList from "../AttendeesModal/CSVList";
import moment from "moment";
import LazyImage from "../common/LazyImage/LazyImage";
import { BestPerformerDownloadIcon, DownloadIconNew, WellnessChampion } from "../../utils/icons";
import HoverButton from "../common/HoverButton";
import { DropdownItem } from "react-bootstrap";

let headersMonth = [];
let headermonthdata=[]
class EmployeesOfTheMonth extends Component {
  constructor() {
    super();
    this.state = {
      userId: null,
      initiativeId: null,
      initiativeName: null,
      dropDownValue: "Month",
      printYearEmployeeCSV: false,
      updatedYearEmployeeCSV: [],
      printMonthEmployeeCSV: false,
      updatedMonthEmployeeCSV: [],
      fileTitleMonth: "EmployeeOfMonth",
      fileTitleQuarter: "EmployeeOfQuarter",
      onHover : -1
    };
  }

  printBestEmployeesOfYear = (bool) => {
    this.printAction(bool);
  }

  printBestEmployeesOfMonth = (month, bool) => {
    this.printActionMonth(month, bool);
  }

  printBestEmployeesOfQuarter = (quarter, bool) => {
    this.printActionQuarter(quarter, bool);
  }

  printAction = (bool) => {
    const { topEmployees } = this.props;
    let updatedYearEmployeeCSV = topEmployees && topEmployees.length > 0 &&
      topEmployees.map((best) => {
        let obj = {};
        obj['NAME'] = best['name'];
        obj['DEPARTMENT'] = best['department'];
        obj['TOTALRECOGNIZED'] = best['total_recognized'];
        return obj;
      })
    this.setState({ printYearEmployeeCSV: bool, updatedYearEmployeeCSV: updatedYearEmployeeCSV })
  };

  printHeaderMonth = (month, bool) => {
    const { monthlyRecognitionData } = this.props;
    if(month!==false){
      let obj={ label: 'User name', key: 'NAME' };
      headersMonth.push(obj);
    }
    if(month!==false){
      monthlyRecognitionData && monthlyRecognitionData[month] && monthlyRecognitionData?.[month]?.[0]?.recognitions?.map((data)=>{
        let obj = { label: data['core_value'], key: data['core_value'].toLowerCase().split(' ').join('_') };
        headersMonth.push(obj);
      })}
    let obj={ label: 'Total Monthly Recognition', key: 'Total_Monthly_Recognition' };
    headersMonth.push(obj);
    this.printActionMonth(month, bool);
  };


  printActionMonth = (month, bool) => {
    const { monthlyRecognitionData } = this.props;
    month!==false&& monthlyRecognitionData && monthlyRecognitionData[month] && monthlyRecognitionData[month].length > 0 &&
    monthlyRecognitionData[month].map((bestv) => {
      let obj = {};
      obj['NAME']=bestv['name'];
      obj['Total_Monthly_Recognition']=bestv['total_monthly_recognition'];
      bestv.recognitions && bestv.recognitions.map((data)=>{
        obj[data['core_value'].toLowerCase().split(' ').join('_')]=data['exemplify_count'];
      })
      headermonthdata.push(obj);
    })
    const monthTitle = `${month} Employee of the Month`;
    if(month!==false){
      this.setState({ printMonthEmployeeCSV: bool, fileTitleMonth: monthTitle, updatedMonthEmployeeCSV: headermonthdata })
    }
    else{
      headermonthdata=[];
      headersMonth=[];
      this.setState({ printMonthEmployeeCSV: bool, fileTitleMonth: monthTitle, updatedMonthEmployeeCSV: headermonthdata })
    }
  };

  printActionQuarter = (quarter, bool, quarterHeadings) => {
    const { topQuarterEmployees } = this.props;
    let updatedQuarterEmployeeCSV = topQuarterEmployees[quarter] && topQuarterEmployees[quarter].length > 0 &&
      topQuarterEmployees[quarter].map((bestQuater) => {
        let obj = {};
        obj['NAME'] = bestQuater['name'];
        obj['TOTALQUARTERLYRECOGNIZED'] = bestQuater['total_quarterly_recognized'];
        return obj;
      })
    const quarterTitle = `Employee of the ${quarterHeadings}`
    this.setState({ printQuarterEmployeeCSV: bool, fileTitleQuarter: quarterTitle, updatedQuarterEmployeeCSV: updatedQuarterEmployeeCSV })
  };

  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name
    })
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null
    })
  };

  onHoverMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveHoverMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  showUser = (employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails) => {
    const { userId, initiativeId, initiativeName } = this.state;

    const Employees = [];

    for (const key in employeesOfTheMonthlylyDetails) {
      if (employeesOfTheMonthlylyDetails[key] !== null) {
        const newObj = { ...employeesOfTheMonthlylyDetails[key], month: `${key}` };
        Employees.push(newObj);
      }
    }

    const Quarters = [];

    for (const key in employeesOfTheMonthByQuarterlyDetails) {
      if (employeesOfTheMonthByQuarterlyDetails[key] !== null) {
        const newObj = { ...employeesOfTheMonthByQuarterlyDetails[key], quater: `${key}` }
        Quarters.push(newObj);
      }
    }
    const quarterHeadings = ['Quarter 1 (Jan - Mar)', 'Quarter 2 (Apr - Jun)', 'Quarter 3 (Jul - Sep)', 'Quarter 4 (Oct - Dec)',]
    return this.state.dropDownValue === "Month"  ?
      Object?.keys(employeesOfTheMonthlylyDetails).length ? Object?.keys(employeesOfTheMonthlylyDetails).map((data, index) => (
        Array.isArray(employeesOfTheMonthlylyDetails[data]) && employeesOfTheMonthlylyDetails[data].length !== 0 ?
          <TopRecognitionContainerV2 key={data.uid || index} >
            <RecognitionReceivedContainerV2 key={index} >
              <RecognitionReceivedHeaderV2 style={{background:'#005c87'}}>
                <span>{data} {moment().format('YYYY')}</span>
                <div style={{ width: '32px', height: '32px', cursor: 'pointer' }} onClick={() => this.printHeaderMonth(data, true)}>
                  <BestPerformerDownloadIcon/>
                </div>
              </RecognitionReceivedHeaderV2>
              <RecognisedUserV2 >
                {employeesOfTheMonthlylyDetails[data].length > 0 && employeesOfTheMonthlylyDetails[data].map((user, index) => (
                  index < 5 &&
                <UserProfileContainerV2 style={{ display: 'flex', alignItems: 'center' }} active={index === 2} key={index}>
                  <LazyImage src={ImageUrl + "/" + user.profile_image} />
                  <UserNameContainerV2>
                    <NameV2 fontFamily={'Rubik-Medium'} color={'#005c87'} textAlign={1}
                      active={true}>{user.name}</NameV2>
                    {user.is_champion ? <WellnessChampion/> : ""}
                  </UserNameContainerV2>
                  <RecognitionFrequencyV2 active={index === 2}>
                    <span>{user.total_monthly_recognized} Times</span>
                  </RecognitionFrequencyV2>
                </UserProfileContainerV2>
                ))}
              </RecognisedUserV2>
            </RecognitionReceivedContainerV2>
          </TopRecognitionContainerV2> : null
      )) :
        null

      :

      this.state.dropDownValue === "Quarter" ?
        Object.keys(employeesOfTheMonthByQuarterlyDetails).length ? Object.keys(employeesOfTheMonthByQuarterlyDetails).map((data, index) => (
          employeesOfTheMonthByQuarterlyDetails[data].length !== 0 &&
          <TopRecognitionContainerV2 key={data.uid || index} >
            <RecognitionReceivedContainerV2 key={index} >
              <RecognitionReceivedHeaderV2 style={{background:'#005c87'}}>
                <span>{quarterHeadings[index]} {moment().format('YYYY')}</span>
                <div style={{ width: '32px', height: '32px', cursor: 'pointer' }} onClick={() => this.printActionQuarter(data, true, quarterHeadings[index])}>
                  <BestPerformerDownloadIcon/>
                </div>
              </RecognitionReceivedHeaderV2>
              <RecognisedUserV2 >
                {employeesOfTheMonthByQuarterlyDetails[data].length > 0 && employeesOfTheMonthByQuarterlyDetails[data].map((user, index) => (
                  index < 5 &&
                  <UserProfileContainerV2 style={{ display: 'flex', alignItems: 'center' }} active={index === 2} key={index}>
                    <Image image={user.profile_image} />
                    <UserNameContainerV2>
                      <NameV2 fontFamily={'Rubik-Medium'} color={'#005c87'} textAlign={1}
                        active={true}>{user.name}</NameV2>
                      {user.is_champion ? <WellnessChampion/> : ""}
                    </UserNameContainerV2>
                    <RecognitionFrequencyV2 active={index === 2}>
                      <span>{user.total_quarterly_recognized} Times</span>
                    </RecognitionFrequencyV2>
                  </UserProfileContainerV2>
                ))}
              </RecognisedUserV2>
            </RecognitionReceivedContainerV2>
          </TopRecognitionContainerV2>
        )) :
          null

        :

        this.state.dropDownValue === "Year" ?
          employeesOfTheMonthDetails.length > 0 ? employeesOfTheMonthDetails.map((data, index) => (
            index < 12 &&
            <CardContainerV3 key={data.uid || index} marginRight={(index + 1) % 4 === 0 ? "0px" : "25px"} style={{border:"1px solid #005c87"}}>
              <GridCardV2 onMouseEnter={() => this.onHoverMouse(data.uid)} onMouseLeave={() => this.onLeaveHoverMouse()}>
                <div className="wellness-champion">
                  {data.is_champion ? <div>Wellness Champions</div> : ""}
                </div>
                <div className="profile">
                  <div>
                    <img onError={this.fallBack} src={`${ImageUrl}/${data.profile_image}`} />
                  </div>
                </div>
                <div className="name">
                  {data.name}
                </div>
                {data.department ? <div className="department">
                  {data.department}
                </div> :
                  <div className="no-department">
                    NA
                  </div>}
                <div className="wellness-card">
                  {data.interests && data.interests.map((Wellness, index) => (
                    index < 5 &&
                    <div key={index} onMouseEnter={() => this.onEnterMouse(data.uid, Wellness.interest_id, Wellness.interest_name)} onMouseLeave={() => this.onLeaveMouse()}>
                      <img src={`${ImageUrl}/${Wellness.interest_icon}`} />
                      {userId === data.uid && initiativeId === Wellness.interest_id &&
                        <div className="hover-card">
                          <div> {initiativeName}</div>
                        </div>}
                    </div>))}
                </div>
                <div className="mutual-buddies">
                  <div>
                    <div className="no-text">{data.total_recognized} Times Recognized</div>
                  </div>
                </div>
                {data && this.state.onHover === data.uid &&
                  <div className="imageBackground">
                    <div className="view-button"
                      onClick={() => this.goToPersonalProfile(data.uid)}
                      background='white'>View Profile
                    </div>
                  </div>}
              </GridCardV2>
            </CardContainerV3>
          )) :
            null
          :
          null
  };

  showEmployees = (employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails) => {
    // window.console.log("employeesOfTheMonthDetails",employeesOfTheMonthDetails)
    // window.console.log("employeesOfTheMonthlylyDetails",employeesOfTheMonthlylyDetails)
    window.console.log("employeesOfTheMonthByQuarterlyDetails",employeesOfTheMonthDetails)
    return(
      <div>
        {/* {isEmpty(employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails)   ?
          <CardContainerr>
            <h3>No Employees of the month data found</h3>
          </CardContainerr> : null} */}
        <CardContainerr>
          {isEmpty(employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails) ? '' : this.showUser(employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails)}
        </CardContainerr>
      </div>
    );
  }

  role = "hammer"
  yearList = ["Month", "Quarter", "Year"]

  updateDropdown = (item) => {
    this.setState({ dropDownValue: item });
  };

  goToPersonalProfile = (employeeId) => {
    const { history } = this.props;
    history.push(`/company/people/${employeeId}/personal-profile`)
  }

  csvHeadersQuarter =
    [
      { label: "NAME", key: "NAME" },
      { label: "TOTAL QUARTERLY RECOGNIZED", key: "TOTALQUARTERLYRECOGNIZED" },
    ]

  render() {
    const { employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails } = this.props;
    const { updatedYearEmployeeCSV, printYearEmployeeCSV, updatedMonthEmployeeCSV, printMonthEmployeeCSV, updatedQuarterEmployeeCSV, printQuarterEmployeeCSV } = this.state;
    const fileTitle = "2022 Employee of the year"
    const csvHeaders =
      [
        { label: "NAME", key: "NAME" },
        { label: "DEPARTMENT", key: "DEPARTMENT" },
        { label: "TOTAL YEARLY RECOGNIZED", key: "TOTALRECOGNIZED" }
      ]
    const csvHeadersQuarter =
      [
        { label: "NAME", key: "NAME" },
        { label: "TOTAL QUARTERLY RECOGNIZED", key: "TOTALQUARTERLYRECOGNIZED" },
      ]
    return (
      <div>

        <div style={{ marginTop: '25px', marginBottom: '25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{display:"flex",alignItems:"center"}}>
            <NewLabelV2 role={'role'}>Employee of the</NewLabelV2>
            {
              <YearDropDownV2 inputpadding="9px"
                type="button"
                label="year"
                title={<div className="title" style={{ fontSize: '16px', lineHeight: '20px', fontFamily: 'Rubik-Medium', color: '#005c87' }}>{this.state.dropDownValue}</div>}
                id="year"
                role={this.role}
                value={this.state.dropDownValue}
                onSelect={(index,/*listItem*/) => this.updateDropdown(this.yearList[index])}
              >
                {
                  this.yearList.map((list, index) => (
                    <DropdownItem eventKey={index} key={index} onSelect={() => this.updateDropdown(list)} style={{fontFamily:"Rubik-Regular",fontSize:"14px"}}>{list}</DropdownItem>
                  ))
                }
              </YearDropDownV2>
            }
          </div>
          <div>
            {this.state.dropDownValue === 'Year' &&
              // <button style={{ cursor: 'pointer', border: 'none', padding: '0px' }} onClick={() => this.printBestEmployeesOfYear(true)}>
              //   <DownloadIconNew/>
              // </button>
              <HoverButton width={"40px"} height={"40px"} title={"Download"} fillOpacity={1} disabled={false} backgroundColor={"#eaeee6"} backgroundColorActive={"#005c87"} activeColor={"#619ab5"} inActiveColor={"white"} svgPath={<DownloadIconNew/>} 
                onClick={() => this.printBestEmployeesOfYear(true)}/>
            }
          </div>

        </div>
        {this.showEmployees(employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails)}
        {printYearEmployeeCSV && <CSVList Title={fileTitle} removeCSVData={this.printAction} headers={csvHeaders} data={updatedYearEmployeeCSV} />}
        {printMonthEmployeeCSV && <CSVList Title={this.state.fileTitleMonth} removeCSVData={this.printActionMonth} headers={headersMonth} data={updatedMonthEmployeeCSV} />}
        {printQuarterEmployeeCSV && <CSVList Title={this.state.fileTitleQuarter} removeCSVData={this.printActionQuarter} headers={csvHeadersQuarter} data={updatedQuarterEmployeeCSV} />}
      </div>
    )
  }
}
EmployeesOfTheMonth.propTypes = {
  employeesOfTheMonthDetails: PropTypes.object,
  // recognitionDetails: PropTypes.array,
  // employeeList: PropTypes.array.isRequired,
  selectedEmployee: PropTypes.any,
  employeesOfTheMonthlylyDetails: PropTypes.object,
  employeesOfTheMonthByQuarterlyDetails: PropTypes.object,
  history: PropTypes.object,
  topEmployees: PropTypes.array,
  topMonthEmployees: PropTypes.array,
  topQuarterEmployees: PropTypes.array,
  monthlyRecognitionData: PropTypes.array
}
export default EmployeesOfTheMonth;